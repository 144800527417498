<template>
    <div>
        <modal-content ref="add-block" :max-width="480" name="add-block" :title="$t('pages.block_admin.add_new_block')" @close="closeBlockModal" :showFooter="false">
            <template #content>
                <FormulateForm @submit="handleClick" #default="{ hasErrors }" class="min-h-full flex-1">
                    <div class="flex flex-col justify-between min-h-full">
                        <FormulateInput class="w-full text-base-content" :label="$t('pages.email_admin.name')" :placeholder="$t('pages.block_admin.block_name')" type="text" validation="required" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="blockData.name" />

                        <FormulateInput class="w-full text-base-content" :label="$t('pages.field_table.label')" :placeholder="$t('pages.block_admin.block_label')" type="text" validation="required" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="blockData.label" />

                        <FormulateInput class="w-full text-base-content" :label="$t('pages.block_admin.block_titles')" :placeholder="$t('pages.block_admin.block_titles')" type="text" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="blockData.block_title" />

                        <FormulateInput
                            v-model="blockData.entity_types"
                            validation="required"
                            class="w-full text-base-content"
                            :label="$t('pages.block_admin.entity_types')"
                            :placeholder="$t('pages.field_table.select_entity_type')"
                            type="multi-select"
                            variant="alt"
                            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                            :options="entityTypeOptions"
                            :loading="isOptionsLoading"
                            :config="ENTITY_TYPES_DROPDOWN_CONFIG"
                        />
                        
                        <FormulateInput class="w-full text-base-content" :label="$t('pages.field_table.description')" :placeholder="$t('pages.block_admin.block_description')" type="textarea" validation="required" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="blockData.description" />

                        <FormulateInput class="text-base-content" :label="$t('pages.block_admin.multi')" :placeholder="$t('pages.block_admin.multi')" type="checkbox" :element-class="(context, classes) => ['pr-2'].concat(classes)" v-model="blockData.multi" />

                        <div class="flex gap-4">
                            <FormulateInput type="number" class="w-full text-base-content" :label="$t('pages.block_admin.min')" :min="blockData.multi ? 1: ''" :placeholder="$t('pages.block_admin.min_count')" :validation="blockData.multi ? 'number|min:1': ''" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" :disabled="!blockData.multi" @keypress="isNumber($event)" v-model="blockData.min_count" />
                            <FormulateInput type="number" class="w-full text-base-content" :label="$t('pages.block_admin.max')" :min="blockData.multi ? blockData.min_count: ''" :placeholder="$t('pages.block_admin.max_count')" :validation="blockData.multi ? `number|min:${blockData.min_count}`: ''" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" :disabled="!blockData.multi" @keypress="isNumber($event)" v-model="blockData.max_count" />
                            <!-- <input type="number" min=0 class="w-full border h-10 px-2" label="Min" :disabled="!blockData.multi" @keypress="isNumber($event)" v-model="blockData.min_count" />
                            <input type="number" class="w-full border h-10 px-2" label="Max" :disabled="!blockData.multi" @keypress="isNumber($event)" v-model="blockData.max_count" /> -->
                        </div>

                        <div class="card-actions justify-end">
                            <Button :loader="isLoading" :text="$t('pages.field_table.save')" type="primary" :disabled="hasErrors || isLoading || isButtonDisabled" />
                        </div>
                    </div>
                </FormulateForm>
            </template>
        </modal-content>
    </div>
</template>
<script>
import Button from "@/components/button";
import modalContent from "@/components/modal-content";
import { isNumber } from "@/plugins/functions";
import { mapActions, mapState } from 'vuex';
import { ENTITY_TYPES_DROPDOWN_CONFIG } from "@shared/utils/constants";

export default {
    name: "add-block",
    components: {
        Button,
        modalContent,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.fetchEntityTypeOptions();
    },
    data() {
        return {
            ENTITY_TYPES_DROPDOWN_CONFIG,
            isOptionsLoading: false,
            blockData: {
                name: "",
                label: "",
                description: "",
                multi: false,
                min_count: 1,
                max_count: 1,
                entity_types: [],
            },
        };
    },
    computed: {
        ...mapState({
            entityTypeOptions: 'entityTypeList',
        }),
        isButtonDisabled() {
            if (this.blockData.name === "" || this.blockData.label === "" || this.blockData.description === "") return true;
            else return false;
        },
    },
    methods: {
        ...mapActions([
            'fetchEntityTypeList',
        ]),
        isNumber,
        async fetchEntityTypeOptions() {
            this.isOptionsLoading = true;
            if (!this.entityTypeOptions.length) {
                await this.fetchEntityTypeList();
            }
            this.isOptionsLoading = false;
        },
        closeBlockModal() {
            this.$refs["add-block"].hideModal();
        },
        showBlockModal() {
            this.handleClearBlockData();
            this.$refs["add-block"].showModal();
        },
        handleClearBlockData() {
            this.blockData = {
                name: "",
                label: "",
                description: "",
                multi: false,
                min_count: 1,
                max_count: 1,
                entity_types: [],
            };
        },
        handleClick() {
            this.blockData.name = this.blockData.name.trim();
            this.blockData.label = this.blockData.label.trim();
            this.blockData.description = this.blockData.description.trim();

            const payload = {
                ...this.blockData,
                entity_types: this.blockData.entity_types.map((el) => ({ entity_type_id: el.id }))
            };

            if (!payload.name.length || !payload.label.length || !payload.description) {
                this.$toast.error(this.$t('pages.block_admin.field_should_not_be_filled_with_spaces'));
            }
            else if (payload.multi && (payload.min_count < 1 || payload.max_count < 1)) {
                this.$toast.error(this.$t('pages.block_admin.min_max_should_be_greater_than_zero'));
            } else {
                
                this.$emit("createNewBlock", payload);
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
