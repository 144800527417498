<!-- Localized -->
<template>
    <div class="flex-1 flex overflow-y-auto p-4 -m-4">
        <tabs @select="selectTab" :selected="tabs.selected" :tabs="tabs.list">
            <template #content-fieldadmin > 
                <FieldAdmin />
            </template>
            <template #content-blockadmin >
                <BlockAdmin />
            </template>
        </tabs>
    </div>
</template>

<script>
import tabs from "@/components/dashboard-components/tabs";
import FieldAdmin from "../field-lists"
import BlockAdmin from "@/pages/block-admin/block-admin-list"
import {checkPermission} from "@shared/utils/functions"

export default {
    name: "field-tab",
    components: {
        tabs,
        FieldAdmin,
        BlockAdmin,
    },
    props: {},
    data() {
        return {
            tabs: {
                selected: "fieldadmin",
                list: [
                    {
                        id: "fieldadmin",
                        label: "Field Admin",
                    },
                    {
                        id: "blockadmin",
                        label: "Block Admin",
                    },
                ],
            },
        };
    },
    computed: {},
    mounted() {
        this.isFieldRedable = checkPermission('field.read');
        this.isBlockReadable = checkPermission('block.read');
        this.tabs.selected = this.isFieldRedable ? this.$route?.query?.tab || "fieldadmin" : "blockadmin";
        this.displayAllowedTabs();
    },
    methods: {
        selectTab(id) {
            this.tabs.selected = id;
            this.$router.push({
                path: this.$route.path,
                query: {
                    tab: this.tabs.selected,
                },
            });
        },
        displayAllowedTabs(){
            if(this.isFieldRedable && !this.isBlockReadable) { this.tabs.list = [{ id: "fieldadmin",label: "Field Admin",}]}
            if(this.isBlockReadable && !this.isFieldRedable){ this.tabs.list = [{ id: "blockadmin", label: "Block Admin",}]}
        },
    },
   
};
</script>

<style lang="scss" scoped>

</style>
